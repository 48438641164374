// Modules
import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthService, TestAuthService } from '@core/auth/auth.service';
import { CoreModule } from '@core/core.module';
import { SignalRHubService } from '@core/services/api/signalr/signalr-hub.service';
import { TestSignalRHubService } from '@core/services/api/signalr/test-signalr-hub.service';
import { CookieService } from '@core/services/internal/cookie.service';
import { UtilPagesModule } from '@modules/util-pages/util-pages.module';
import { GlobalErrorHandler } from '@plugins/global-error-handler.service';
import { UserSessionGuard } from '@shared/guards/user-session.guard';
import { SharedModule } from '@shared/shared.module';
import { AppComponent } from './app.component';
import { APP_ROUTES } from './app.routes';
import { AppViewComponent } from './app.view.component';
import { AppService } from './core/services/internal/app.service';
import { provideSentinelService, withNewRelic } from '@bannerflow/sentinel';
import { NewRelicPlugin } from '@plugins/new-relic.plugin';
import { AppConfig } from '@config/app.config';

@NgModule({
    imports: [BrowserAnimationsModule, CommonModule, CoreModule, SharedModule, UtilPagesModule, APP_ROUTES],
    declarations: [AppComponent, AppViewComponent],
    providers: [
        AppService,
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: AuthService, useClass: CookieService.get('IN_TEST') ? TestAuthService : AuthService },
        {
            provide: SignalRHubService,
            useClass: CookieService.get('IN_TEST') ? TestSignalRHubService : SignalRHubService
        },
        UserSessionGuard,
        provideSentinelService(
            withNewRelic({
                enabled: AppConfig.config.STAGE !== 'local',
                ...NewRelicPlugin.init(AppConfig.config.STAGE === 'production', [
                    AppConfig.config.IDP_URL,
                    AppConfig.config.LIST_SERVICE_URL,
                    AppConfig.config.STUDIO_URL
                ])
            })
        )
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
