import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthHttpInterceptor } from '@core/auth/auth.interceptor';
import { AppStoreModule } from './store/app-store.module';

@NgModule({
    imports: [HttpClientModule, AppStoreModule],
    providers: [
        // We have to put this with HttpClientModule
        // https://angular.io/guide/http#provide-the-interceptor
        { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true }
    ]
})
export class CoreModule {}
