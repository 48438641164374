import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AppConfig } from '@config/app.config';
import {
    AzureAppConfigFeatureService,
    AzureAppConfigurations
} from '@core/services/api/azure/azure-app-config-feature.service';

@Injectable({
    providedIn: 'root'
})
export class B2RedirectGuard implements CanActivate {
    constructor(private azAppConfigService: AzureAppConfigFeatureService) {}

    public async canActivate(): Promise<boolean> {
        const isRoutingEnabled = await this.azAppConfigService.getConfigurationSetting(
            AzureAppConfigurations.B2ClientRouting,
            true
        );

        if (!isRoutingEnabled) {
            // takes user to b2 route
            window.location.href = `${AppConfig.config.B2_URL}/v2${window.location.pathname}`;
            return false;
        } else {
            return true;
        }
    }
}
