// modules
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UIModule } from '@bannerflow/ui';
import { View404Component } from './404/404.view.component';
import { MaintenanceModeComponent } from './maintenance/maintenance-mode.component';
import { NotSupportedComponent } from './not-supported/not-supported.component';
import { OopsComponent } from './oops/oops.component';
import { B2RedirectComponent } from './redirect/b2-redirect.component';
import { SignIncallbackComponent } from './sign-incallback/sign-incallback.component';

@NgModule({
    imports: [CommonModule, RouterModule, UIModule],
    declarations: [
        View404Component,
        NotSupportedComponent,
        OopsComponent,
        MaintenanceModeComponent,
        B2RedirectComponent,
        SignIncallbackComponent
    ],
    exports: [View404Component, NotSupportedComponent, OopsComponent, MaintenanceModeComponent, B2RedirectComponent]
})
export class UtilPagesModule {}
