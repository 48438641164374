import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConfig } from '@config/app.config';
import { SessionService } from '@core/services/internal/session.service';

@Component({
    selector: 'landingPageFolderView',
    template: ''
})
export class LandingPageFolderViewComponent implements OnDestroy {
    private sub: any;
    constructor(private sessionService: SessionService, private activatedRoute: ActivatedRoute) {
        let folderIdParam = '';
        this.sub = this.activatedRoute.params.subscribe(params => {
            folderIdParam = `/f/${params['folderId']}`;
        });

        window.location.href = `${AppConfig.config.B2_URL}/${this.sessionService.user.account.slug}/${this.sessionService.user.brand.slug}/LandingPages${folderIdParam}`;
    }

    public ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}
