import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';

@Component({
    selector: 'sign-incallback',
    templateUrl: './sign-incallback.component.html'
})
export class SignIncallbackComponent implements OnInit {
    constructor(
        private readonly router: Router,
        private readonly authService: AuthService
    ) {}

    async ngOnInit(): Promise<void> {
        await this.authService.userManager.signinCallback();
        const path = localStorage.getItem('lastLocation');

        this.router.navigateByUrl(path);
    }
}
