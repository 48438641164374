import { Component, OnDestroy, OnInit } from '@angular/core';
import { CampaignManagerFeatureCheckService } from '@core/services/internal/campaign-manager-feature-check.service';
import { SessionService } from '@core/services/internal/session.service';
import { SidebarService } from '@core/services/internal/sidebar.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'sidebar',
    templateUrl: 'sidebar.component.html',
    styleUrls: ['sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
    isExpanded: boolean;

    private ngUnsubscribe$ = new Subject<void>();

    constructor(
        public sessionService: SessionService,
        public campaignManagerFeatureCheck: CampaignManagerFeatureCheckService,
        private sidebarService: SidebarService
    ) {}

    ngOnInit(): void {
        this.sidebarService
            .isExpanded()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(isExpanded => (this.isExpanded = isExpanded));
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
    }

    public showIfUpsellEnabled(feature: string): boolean {
        const showUpsale: boolean = this.showIfFeatureEnabled('UPSELLVIEWS');

        if (showUpsale) {
            return true;
        }

        return this.showIfFeatureEnabled(feature);
    }

    /**
     * show/hide sidebar item independently from UPSELL state
     */
    toggle(): void {
        this.sidebarService.toggle();
    }

    public showIfFeatureEnabled(feature: string): boolean {
        return this.sessionService.hasFeature(SessionService.FEATURES[feature]);
    }
}
