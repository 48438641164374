<div
    class="sidebar"
    [ngClass]="{ expand: isExpanded, collapse: !isExpanded }">
    <div
        class="arrow-button"
        [attr.data-testid]="'sidebar-expand-toggle'"
        (click)="toggle()">
        <ui-svg-icon [icon]="isExpanded ? 'arrow-left' : 'arrow-right'"></ui-svg-icon>
    </div>
    <div class="container">
        <div
            class="sidebar__item left-align"
            id="interaction-navigate-to-creative-sets"
            [routerLink]="['./creative-sets']"
            [routerLinkActive]="['sidebar__item--active']"
            uiTooltip="Creative sets"
            [uiTooltipDisabled]="isExpanded"
            uiTooltipPosition="right">
            <ui-svg-icon icon="creative-set"></ui-svg-icon>
            <span
                *ngIf="isExpanded"
                class="menu-text">
                Creative sets
            </span>
        </div>

        <ng-container *ngIf="!showIfFeatureEnabled('HIDEBANNERBUILDER')">
            <div
                class="sidebar__item"
                id="interaction-navigate-to-creative-sets"
                (click)="sessionService.gotoA1Url('BannerSets')"
                uiTooltip="Banner sets (Legacy)"
                [uiTooltipDisabled]="isExpanded"
                uiTooltipPosition="right">
                <ui-svg-icon [icon]="'banner-sets'"></ui-svg-icon>
                <span
                    *ngIf="isExpanded"
                    class="menu-text">
                    Banner sets (Legacy)
                </span>
            </div>
        </ng-container>

        <div
            *ngIf="showIfUpsellEnabled('SCHEDULING') && !showIfFeatureEnabled('DISABLEBANNERBUILDER')"
            class="sidebar__item"
            id="interaction-navigate-to-schedules"
            (click)="sessionService.gotoA1Url('Schedules')"
            uiTooltip="Schedules (Legacy)"
            [uiTooltipDisabled]="isExpanded"
            uiTooltipPosition="right">
            <i
                aria-hidden="true"
                class="bf-icon bf-icon-schedule"></i>
            <span
                *ngIf="isExpanded"
                class="menu-text">
                Schedules (Legacy)
            </span>
        </div>

        <div
            *ngIf="campaignManagerFeatureCheck.canUseCampaignList()"
            class="sidebar__item"
            id="interaction-navigate-to-campaign-list"
            [routerLink]="['./campaigns']"
            [routerLinkActive]="['sidebar__item--active']"
            uiTooltip="Campaign Manager"
            [uiTooltipDisabled]="isExpanded"
            uiTooltipPosition="right">
            <ui-svg-icon icon="campaigns-l"></ui-svg-icon>
            <span
                *ngIf="isExpanded"
                class="menu-text">
                Campaign manager
            </span>
        </div>

        <div
            *ngIf="showIfFeatureEnabled('ANALYTICS')"
            class="sidebar__item"
            id="interaction-navigate-to-analytics"
            (click)="sessionService.goAnalytics()"
            uiTooltip="Analytics"
            [uiTooltipDisabled]="isExpanded"
            uiTooltipPosition="right">
            <i
                aria-hidden="true"
                class="bf-icon bf-icon-analytics"></i>
            <span
                *ngIf="isExpanded"
                class="menu-text">
                Analytics
            </span>
        </div>

        <div
            *ngIf="showIfUpsellEnabled('FEEDS')"
            class="sidebar__item"
            id="interaction-navigate-to-feeds"
            (click)="sessionService.gotoA1Url('Feeds')"
            uiTooltip="Feeds"
            [uiTooltipDisabled]="isExpanded"
            uiTooltipPosition="right">
            <i
                aria-hidden="true"
                class="bf-icon bf-icon-feeds"></i>
            <span
                *ngIf="isExpanded"
                class="menu-text"
                >Feeds</span
            >
        </div>

        <div
            *ngIf="showIfFeatureEnabled('SOCIALDYNAMICADVERTISING')"
            class="sidebar__item"
            id="interaction-navigate-to-social-dynamic-advertising"
            [routerLink]="['./feeds']"
            [routerLinkActive]="['sidebar__item--active']"
            uiTooltip="Dynamic Advertising"
            [uiTooltipDisabled]="isExpanded"
            uiTooltipPosition="right">
            <ui-svg-icon icon="dynamic-feed"></ui-svg-icon>
            <span
                *ngIf="isExpanded"
                class="menu-text"
                >Dynamic Advertising</span
            >
        </div>

        <div
            *ngIf="showIfUpsellEnabled('LANDINGPAGE')"
            class="sidebar__item"
            id="interaction-navigate-to-landing-pages"
            (click)="sessionService.gotoA1Url('LandingPages')"
            uiTooltip="Landing pages"
            [uiTooltipDisabled]="isExpanded"
            uiTooltipPosition="right">
            <i
                aria-hidden="true"
                class="bf-icon bf-icon-landingpage"></i>
            <span
                *ngIf="isExpanded"
                class="menu-text">
                Landing pages
            </span>
        </div>

        <ng-container>
            <hr />
            <div
                class="sidebar__item left-align"
                id="interaction-navigate-to-trash"
                [routerLink]="['./trash-bin']"
                [routerLinkActive]="['sidebar__item--active']"
                uiTooltip="Trash bin"
                [uiTooltipDisabled]="isExpanded"
                uiTooltipPosition="right">
                <ui-svg-icon
                    icon="delete"
                    class="delete-icon"></ui-svg-icon>
                <span
                    *ngIf="isExpanded"
                    class="menu-text">
                    Trash bin
                </span>
            </div>
        </ng-container>
    </div>
</div>
