import { Injectable } from '@angular/core';
import { AppConfig } from '@config/app.config';
import { AuthService } from '@core/auth/auth.service';
import { SessionService } from '@core/services/internal/session.service';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { HeaderConstants } from '@shared/constants/header-constants';

@Injectable({
    providedIn: 'root'
})
export class SignalRHubService {
    constructor(
        private authService: AuthService, 
        private sessionService: SessionService
        ) {}
    
    public createConnection(url: string): HubConnection {
        const headers = this.prepareHeaders();
        const logLevel = this.determineLogLevel();

        return new HubConnectionBuilder()
            .withUrl(url, { accessTokenFactory: () => this.authService.getAccessToken(), headers })
            .withAutomaticReconnect()
            .configureLogging(logLevel)
            .build();
    }

    private prepareHeaders(): Record<string, string> {
        return {
            [HeaderConstants.BfBrandId]: this.sessionService?.user?.brand?.id ?? ''
        };
    }

    private determineLogLevel(): LogLevel {
        return AppConfig.config.STAGE === 'production' ? LogLevel.None : LogLevel.Debug;
    }
}
