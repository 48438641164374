import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AzureAppConfigurations } from '@core/services/api/azure/azure-app-config-feature.service';
import { SessionService } from '@core/services/internal/session.service';
import { LandingPageFolderViewComponent } from '@modules/landingpage/views/list/landingPageFolder.view.component';
import { View404Component } from '@modules/util-pages/404/404.view.component';
import { MaintenanceModeComponent } from '@modules/util-pages/maintenance/maintenance-mode.component';
import { NotSupportedComponent } from '@modules/util-pages/not-supported/not-supported.component';
import { OopsComponent } from '@modules/util-pages/oops/oops.component';
import { B2RedirectComponent } from '@modules/util-pages/redirect/b2-redirect.component';
import { SignIncallbackComponent } from '@modules/util-pages/sign-incallback/sign-incallback.component';
import { B2RedirectGuard } from '@shared/guards/b2-redirect.guard';
import { FeatureGuard } from '@shared/guards/feature.guard';
import { listRedirectGuard } from '@shared/guards/list-redirect.guard';
import { MaintenanceModeGuard } from '@shared/guards/maintenance-mode.guard';
import { UserSessionGuard } from '@shared/guards/user-session.guard';
import { AppViewComponent } from './app.view.component';
import { userResolver } from './user.resolver';

const routes: Routes = [
    { path: 'signin-callback', component: SignIncallbackComponent },
    {
        path: 'p/:previewSlug',
        loadChildren: () => import('@modules/banner/preview/preview.module').then(m => m.PreviewModule)
    },
    {
        path: ':accountSlug/:brandSlug',
        component: AppViewComponent,
        resolve: { user: userResolver },
        canActivate: [B2RedirectGuard, UserSessionGuard],
        children: [
            {
                path: 'settings',
                loadChildren: () => import('@modules/settings/settings.module').then(m => m.SettingsModule)
            },
            {
                path: 'campaigns',
                canActivate: [MaintenanceModeGuard, FeatureGuard],
                data: {
                    maintenanceModeConfig: AzureAppConfigurations.CampaignManagerMaintenance,
                    requiredFeatures: [
                        SessionService.FEATURES.CAMPAIGNMANAGER,
                        SessionService.FEATURES.SOCIALCAMPAIGNMANAGER
                    ],
                    allMustMatch: false
                },
                loadChildren: () => import('@modules/campaigns/campaign.module').then(m => m.CampaignModule)
            },

            {
                path: 'analytics',
                canActivate: [FeatureGuard],
                data: { requiredFeatures: [SessionService.FEATURES.ANALYTICS], allMustMatch: true },
                loadChildren: () => import('@modules/analytics/analytics.module').then(m => m.AnalyticsModule)
            },
            {
                path: 'creative-sets',
                canActivate: [UserSessionGuard, listRedirectGuard],
                loadComponent: () =>
                    import('@modules/creative-set/creative-set-page.component').then(m => m.CreativeSetPageComponent)
            },
            {
                path: 'social-campaigns',
                loadComponent: () =>
                    import('@modules/social-campaigns/social-campaigns.component').then(m => m.SocialCampaignsComponent)
            },
            {
                path: 'trash-bin',
                canActivate: [UserSessionGuard],
                loadComponent: () => import('@modules/trash-bin/trash-bin.component').then(m => m.TrashBinComponent)
            },
            {
                path: 'display-campaigns',
                canActivate: [UserSessionGuard],
                loadComponent: () =>
                    import('@modules/display-campaigns/display-campaigns.component').then(
                        m => m.DisplayCampaignsComponent
                    )
            },
            {
                path: 'feeds',
                canActivate: [UserSessionGuard],
                loadComponent: () => import('@modules/feeds/feeds.component').then(m => m.FeedsComponent)
            },
            {
                path: 'landingpagesv1',
                component: LandingPageFolderViewComponent
            },
            {
                path: 'landingpagesv1/:folderId',
                component: LandingPageFolderViewComponent
            },
            {
                path: 'landingpages',
                loadChildren: () => import('@modules/landingpage/landingPage.module').then(m => m.LandingPageModule)
            },
            {
                path: 'user',
                data: {
                    hideSidebar: false,
                    hideWelcome: true
                },
                loadChildren: () => import('@modules/user/user.module').then(m => m.UserModule)
            },
            {
                path: 'product',
                data: {
                    hideSidebar: true,
                    hideWelcome: true,
                    hideBrandPicker: true
                },
                loadChildren: () => import('@modules/product/product.module').then(m => m.ProductModule)
            },
            {
                path: '',
                pathMatch: 'full',
                component: B2RedirectComponent
            },
            {
                path: '404',
                data: { fullPage: false },
                component: View404Component
            },
            {
                path: 'maintenance-mode',
                component: MaintenanceModeComponent
            },
            {
                path: '**',
                redirectTo: '404'
            }
        ]
    },
    {
        path: '',
        pathMatch: 'full',
        component: B2RedirectComponent
    },
    {
        path: '404',
        data: { fullPage: true },
        component: View404Component
    },
    {
        path: 'Oops',
        component: OopsComponent
    },
    {
        path: 'not-supported',
        component: NotSupportedComponent
    },
    {
        path: '**',
        redirectTo: '404'
    }
];

export const APP_ROUTES: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, {});
