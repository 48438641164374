import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { SessionService } from '@core/services/internal/session.service';
import { User } from '@shared/models/user.model';

@Injectable()
export class UserSessionGuard implements CanActivate {
    constructor(private sessionService: SessionService, private router: Router, private authService: AuthService) {}

    public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        if ((await this.authService.getAccessToken()) === '') {
            return false;
        }

        const user: User = await this.sessionService.getUserByActivatedRouteSnapshot(route);

        // Make sure user is logged in.
        if (this.sessionService.isLoggedIn() && user) {
            return true;
        }

        this.router.navigate(['/']);

        return false;
    }
}
