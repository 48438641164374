import { Injectable, inject } from '@angular/core';
import { SessionService } from './session.service';
import { ProfileService } from '../api/profile/profile.service';
import { InfoService } from '../api/bannerflow/info.service';
import { AppConfig } from '@config/app.config';
import { initGTM } from '@plugins/scripts/js/gtm';
import { User } from '@shared/models/user.model';

@Injectable({
    providedIn: 'root'
})
export class InitializationService {
    private sessionService = inject(SessionService);
    private profileService = inject(ProfileService);
    private infoService = inject(InfoService);

    async initialize(): Promise<void> {
        const user: User = this.sessionService.user;
        if (user) {
            const userTitle = await this.profileService.getUserTitle(user.externalId).catch(() => undefined);
            this.sessionService.user.title = userTitle?.name;

            await this.infoService.initBFInfoGlobalVariable();

            if (AppConfig.config.GTM_ID && AppConfig.config.STAGE !== 'local') {
                initGTM(AppConfig.config.GTM_ID);
            }
        }
    }
}
