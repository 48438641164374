import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';
import { AppConfig } from '@config/app.config';

export class TestAuthService {
    public getAccessToken(): string {
        return 'token';
    }
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    userManager: UserManager;
    private user: User;
    private silentRenewErrors = 0;
    private config: UserManagerSettings = {
        authority: `${AppConfig.config.IDP_URL}`,
        client_id: 'BannerflowClientJS',
        redirect_uri: `${AppConfig.config.APP_URL}/signin-callback`,
        scope: 'openid bannerflow publishservice campaignservice studio analyticsbackend socialcampaignservice socialaccountservice profileservice listservice offline_access',
        response_type: 'code',
        post_logout_redirect_uri: `${AppConfig.config.APP_URL}`,
        userStore: new WebStorageStateStore({ store: window.localStorage }),
        automaticSilentRenew: true,
        silent_redirect_uri: `${AppConfig.config.APP_URL}/assets/silent-redirect.html`,
        accessTokenExpiringNotificationTimeInSeconds: 20
    };

    constructor() {
        this.userManager = new UserManager(this.config);

        // clear stale state
        this.userManager.clearStaleState();

        // Setup event to listen to silent refreshes
        this.userManager.events.addUserLoaded(() => {
            this.userManager.getUser().then((user: User) => {
                this.setUser(user);
            });
        });

        this.userManager.events.addSilentRenewError(val => {
            this.handleSilentError();
        });
    }

    private async authenticateUser(): Promise<void> {
        if (!this.user || this.user.expired) {
            // Fetch user and update
            const user: User = await this.userManager.getUser();

            if (user && !user.expired) {
                this.setUser(user);
            } else {
                this.loginWithRedirect();
            }
        }
    }

    private handleSilentError(): void {
        this.silentRenewErrors++;
        if (this.silentRenewErrors > 100) {
            this.loginWithRedirect();
        }

        setTimeout(() => {
            this.userManager.signinSilent().catch(() => {
                this.handleSilentError();
            });
        }, 10000);
    }

    private setUser(user: User): void {
        if (user && !user.expired) {
            this.user = user;
        }
    }

    public clearUser(): void {
        this.userManager.removeUser();
    }

    public loginWithRedirect(): Promise<void> {
        // saves lastLocation and oidc-redirect.html deletes it
        localStorage.setItem('lastLocation', window.location.pathname + window.location.search);
        return this.userManager.signinRedirect();
    }

    public loginPopup(): Promise<void> {
        return this.userManager.signinPopup().then((user: User) => {
            this.setUser(user);
        });
    }

    public logout(): Promise<void> {
        return this.userManager.signoutRedirect();
    }

    public async isLoggedIn(): Promise<boolean> {
        const user: User = await this.userManager.getUser();

        return user !== null && user.access_token !== null && !user.expired;
    }

    public async getAccessToken(): Promise<string> {
        await this.authenticateUser();

        return this.user ? this.user.access_token : '';
    }

    public async setupHeaders(
        headers: { [key: string]: string } = {},
        contentType: string = 'application/json'
    ): Promise<HttpHeaders> {
        return new HttpHeaders({
            'Content-Type': contentType,
            ...headers
        });
    }

    public refreshTokenSilent(): void {
        this.userManager.clearStaleState();
        this.userManager.signinSilent();
    }
}
